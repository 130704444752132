import {
  DropZone,
  hooks,
  inDesigner,
  registerToolboxSection
} from "./chunk-W4ID6LS4.js";

// src/designer.ts
var {
  useDesignerApi,
  useInlineEditRef,
  useInteraction,
  useSectionRef,
  useSectionRefs,
  useInlineEditSectionRef,
  useInlineEditRegionRef,
  useDesignerInteractionRef,
  useDropping,
  useRegionInteractionRef
} = hooks;
export {
  DropZone,
  inDesigner,
  registerToolboxSection,
  useDesignerApi,
  useDesignerInteractionRef,
  useDropping,
  useInlineEditRef,
  useInlineEditRegionRef,
  useInlineEditSectionRef,
  useInteraction,
  useRegionInteractionRef,
  useSectionRef,
  useSectionRefs
};
